import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'

// component
// ==========================================================================================
const Circle = ({ position }) => {
  return <div className={classNames(css.circle, css[position])} aria-hidden />
}

export default Circle
