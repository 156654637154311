import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import css from './styles.module.scss'
import Circle from '../Circle'
import Image from '../Image'

// component
// ==========================================================================================
const CircleImage = ({ d = 0.1, image, className, position }) => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver })
  const { scrollY } = useViewportScroll()
  const [midPoint, setMidPoint] = useState(0)
  const scrollRange = [midPoint - 0.5, midPoint + 0.5]
  const parallaxRange = [d / 2, -d / 2]

  useEffect(() => {
    const setValues = () => {
      const { pageYOffset, innerHeight } = window
      const mid = pageYOffset + bounds.top + bounds.height / 2 - innerHeight / 2
      setMidPoint(mid)
    }

    setValues()
    document.addEventListener('load', setValues)
    window.addEventListener('resize', setValues)

    return () => {
      document.removeEventListener('load', setValues)
      window.removeEventListener('resize', setValues)
    }
  }, [bounds.top, bounds.height])

  const y = useTransform(scrollY, scrollRange, parallaxRange, {
    clamp: false,
  })

  return (
    image && (
      <div className={classNames(className, css.wrap)}>
        <motion.div
          style={{ y }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={css.motionWrap}
          ref={ref}
        >
          <Circle position={position} />
        </motion.div>

        <Image {...image} className={css.image} />
      </div>
    )
  )
}

export default CircleImage
